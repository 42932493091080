import React, { useCallback, useEffect } from "react"
import HubspotForm from 'react-hubspot-form'
import { Trans, useTranslation } from 'react-i18next'

const ContactForm = () => {
  const { t } = useTranslation()

  const hubspotFormId = t("Hubspot-FormId")

  // hubspot requires jquery, but we can do the same without
  const handler = useCallback((event) => {
    if (event.data.type === 'hsFormCallback'
      && event.data.eventName === 'onFormSubmitted'
      && event.data.id === hubspotFormId) {
      // @ts-ignore injected by google tag manager
      window.dataLayer.push({
        'event': 'ga_event',
        'eventCategory': 'form submission',
        'eventAction': 'formular',
        'eventLabel': 'kontakt'
      })
    }
  }, [hubspotFormId])

  useEffect(() => {
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    }
  }, [])

  return (
    <div className="pb-10 lg:col-span-2 xl:px-12 xl:pb-12">
      <HubspotForm
        portalId='25733058'
        formId={hubspotFormId}
        loading={<p><Trans>Loading</Trans>…</p>}
      />
    </div>
  )
}

export default ContactForm
