import React, { useEffect, useRef } from "react"
import { sr, srConfig } from "../../util/sr"
import BackgroundSquare from "../common/BackgroundSquare"

interface IColumnLayoutProps {
  title: string
  description: string | React.ReactNode
  children: React.ReactNode
}

const ColumnLayout = ({ children, description, title }: IColumnLayoutProps) => {
  const textRef = useRef(null)
  const contentRef = useRef(null)

  useEffect(() => {
    if (sr && textRef.current && contentRef.current) {
      sr.reveal(textRef.current, srConfig(250, 0))
      sr.reveal(contentRef.current, srConfig(300, 0))
    }
  }, [])

  return (
    <div className="pt-20 base-container">
      <BackgroundSquare />
      <div ref={textRef} className="load-hidden">
        <h1 className="mb-6">{title}</h1>
        <div className="grid lg:grid-cols-3">
          <div className="w-full mb-12 text-black lg:pr-16 md:w-2/3 lg:w-full">
            {description}
          </div>
          <div ref={contentRef} className="lg:col-span-2 load-hidden">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ColumnLayout
