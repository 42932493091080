import React from "react"

import SEO from "../components/seo"
import BaseLayout from "../components/layout/baseLayout"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { langFileFilter } from "../util/langUtils"
import Employee from "../components/about/Employee"
import ColumnLayout from "../components/layout/ColumnLayout"
import BusinessStory from "../components/about/BusinessStory"
import EmployeeList from "../components/about/EmployeeList"
import { PhoneIcon } from "@heroicons/react/solid"
import { MailIcon } from "@heroicons/react/outline"
import ContactForm from "../components/contact/ContactForm"

const ContactPage = () => {
  const { t, i18n } = useTranslation()
  const { language } = i18n

  return (
    <>
      <SEO title={t("Contact")} isHome={false} />
      <BaseLayout>
        <div className="mb-12">
          <ColumnLayout
            title={t("Contact-Title")}
            description={
              <div className="space-y-8">
                <div>
                  <Trans>Contact-Description</Trans>
                </div>
                <div className="">
                  <div className="font-bold">hae.sh GmbH</div>
                  <div>
                    <Trans>Company-Address</Trans>
                  </div>
                  <div>
                    <Trans>Company-Zip</Trans>
                  </div>
                </div>
                <div>
                  <div className="">
                    <div className="flex space-x-2">
                      <PhoneIcon className="h-4 my-auto" />
                      <div>Tel. +43 (0) 676 3203211</div>
                    </div>
                    <div className="flex space-x-2">
                      <MailIcon className="h-4 my-auto" />
                      <div>office@hae.sh</div>
                    </div>
                  </div>
                </div>
              </div>
            }
          >
            <ContactForm />
          </ColumnLayout>
        </div>
      </BaseLayout>
    </>
  )
}

export default ContactPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
